import http from "@/utils/services/http"

export const listFomaPagamento = (limit, page, filtros) => {
    let url = '/api/fin-formas-pagamento?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findFomaPagamento = (id) => {
    let url = `/api/fin-formas-pagamento/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newFomaPagamento = (data) => {
    let url = `/api/fin-formas-pagamento`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateFomaPagamento = (fomaPagamento, method = 'PUT') => {
    let url = `/api/fin-formas-pagamento/${fomaPagamento.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, fomaPagamento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteFomaPagamento = (id) => {
    let url = `/api/fin-formas-pagamento/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
