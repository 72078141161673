export const REPETIR_DIA = 1
export const REPETIR_SEMANAL = 2
export const REPETIR_MENSAL = 3
export const REPETIR_BIMESTRAL = 4
export const REPETIR_TRIMESTRAL = 5
export const REPETIR_SEMESTRAL = 6
export const REPETIR_ANUAL = 7

export const REPETIR = {
    [REPETIR_DIA]: {title: 'Diariamente'},
    [REPETIR_SEMANAL]: {title: 'Semanal'},
    [REPETIR_MENSAL]: {title: 'Mensal'},
    [REPETIR_BIMESTRAL]: {title: 'Bimestral'},
    [REPETIR_TRIMESTRAL]: {title: 'Trimestral'},
    [REPETIR_SEMESTRAL]: {title: 'Semestral'},
    [REPETIR_ANUAL]: {title: 'Anual'},
}
