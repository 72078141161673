<template>
  <autocomplete ref="input" :placeholder="placeholder" :parse="parse" :search="search" v-model="model" :min-characters="3" :is-loading="loading" caret />
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {list} from "@/domain/processos/services"
const search = function (terms, tipo) {
  let filtros = encodeURI(`&sortBy=name&descending=false&search=${terms}`)
  return list(10, 1, filtros)
}
export default {
  name: "GlobalProcessoInput",
  components: {Autocomplete},
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false
    }
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      this.$emit('input', this.model)
    },
    value(v) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.model !== v) {
            this.model = v
            // this.$refs.input.set(v)
          }
        }, 100)
      })
    }
  },
  methods: {
    parse (v) {
      console.log('Parse', v)
      return v && v.numero
    },
    search(terms, done) {
      this.loading = true
      search(terms)
          .then(response => {
            this.loading = false
           let  extra = null
            if (terms) {
              extra = {
                label: `Adicionar novo processo`,
                value: terms,
                isNew: true,
                link: true,
                click: () => {
                  window.open('/#/pessoas', '_blank')
                }
              }
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.numero,
                value: p.numero,
                active: null
              }
            })
            extra && result.push(extra)
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
  }
}
</script>
