<template>
  <autocomplete :search="search" :parse="parse" v-model="model" :min-characters="0" :is-loading="loading" caret />
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {listBanco as list} from "@/domain/financeiro/services/banco"
const search = function (terms, tipo) {
  let filtros = encodeURI(`&tipo=${tipo}&sortBy=name&descending=false&search=${terms}`)
  return list(50, 1, filtros)
}
export default {
  name: "BancoInput",
  components: {Autocomplete},
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      this.$emit('input', this.model)
    }
  },
  methods: {
    parse (v) {
      return v && v.nome
    },
    search(terms, done) {
      this.loading = true
      search(terms)
          .then(response => {
            this.loading = false
           let  extra = null
            if (terms) {
              extra = {
                label: `Adicionar novo nanco`,
                value: terms,
                isNew: true,
                link: true,
                click: () => {
                  window.open('/#/configuracoes/globais/financeiro#bancos', '_blank')
                }
              }
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.nome,
                value: p.nome,
                active: null
              }
            })
            extra && result.push(extra)
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
  }
}
</script>
