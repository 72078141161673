export default {
    id: null,
    descricao: null,
    dataCompetencia: null,
    dataVencimento: null,
    observacoes: null,
    valor: null,
    tipo: null,
    repetir: false,
    repetirIntervalo: 3,
    repetirOcorrencias: null,
    pago: false,
    dataPagamento: null,
    descontos: null,
    jurosMulta: null,
    valorPago: null,
    pessoa: null,
    centroCusto: null,
    conta: null,
    categoria: null,
    formaPagamento: null,
    status: null,
    anexos: null,
    tags: null,
    numeroDoc: null,
    processo: null,
    dedutivel: false,
    parcelado: false,
    parcelas: 1,
    rateio: false,
    detalhes: []
}

export const mockDetalhe = {
    id: null,
    descricao: null,
    competencia: null,
    categoria: null,
    centroCusto: null,
    pessoa: null,
    valor: null,
    porcentagem: null
}

export const mockParcela = {
    id: null,
    parcela: 1,
    vencimento: null,
    valor: null,
    pago: false,
    dataPagamento: null
}
