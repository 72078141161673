<template>
  <input v-money="money" v-model="model" />
</template>

<script>
import {REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"

export default {
  name: "DetalheValorInput",
  directives: {money: VMoney},
  props: ['value'],
  data () {
    return {
      money: REAL_BRL,
      model: this.value
    }
  },
  watch: {
    value (v) {
      this.model = v
    },
    model (v) {
      if (v !== this.value) {
        this.$emit('input', v)
      }
      this.$emit('change')
    }
  }
}
</script>
