import http from "@/utils/services/http"

export const listCentroCusto = (limit, page, filtros) => {
    let url = '/api/centro-custo?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findCentroCusto = (id) => {
    let url = `/api/centro-custo/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newCentroCusto = (data) => {
    let url = `/api/centro-custo`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateCentroCusto = (centroCusto, method = 'PUT') => {
    let url = `/api/centro-custo/${centroCusto.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, centroCusto)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteCentroCusto = (id) => {
    let url = `/api/centro-custo/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
