<template>
  <autocomplete ref="input" :transform-model="true" :autofocus="autofocus" :placeholder="placeholder" :parse="parse" :search="search" v-model="model" :min-characters="2" :is-loading="loading" />
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {findDescricao as list} from "@/domain/financeiro/services"
const search = function (terms) {
  return list(terms)
}
export default {
  name: "FinDescricaoInput",
  components: {Autocomplete},
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      console.log('Model: ', v)
      this.$emit('input', this.model)
    },
    value(v) {
      this.$nextTick(() => {
        if (this.model !== v) {
          this.model = v
          this.$refs.input.set(v)
        }
      })
    }
  },
  methods: {
    parse (v) {
      return v && v.nome
    },
    search(terms, done) {
      this.loading = true
      search(terms)
          .then(response => {
            this.loading = false
            let  extra = null
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.descricao,
                value: p.descricao,
                active: null
              }
            })
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
    keydown (k) {
      console.log(k)
    }
  }
}
</script>
