<template>
  <autocomplete ref="input" :placeholder="placeholder" :parse="parse" :search="search" v-model="model" :min-characters="0" :is-loading="loading" caret />
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {listCentroCusto as list} from "@/domain/financeiro/services/centroCusto"
const search = function (terms, tipo) {
  let filtros = encodeURI(`&sortBy=name&descending=false&search=${terms}`)
  return list(50, 1, filtros)
}
export default {
  name: "CentroCustoInput",
  components: {Autocomplete},
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false
    }
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      this.$emit('input', this.model)
    },
    value(v) {
      this.$nextTick(() => {
        if (this.model !== v) {
          this.model = v
          this.$refs.input.set(v)
        }
      })
    }
  },
  methods: {
    parse (v) {
      return v && v.nome
    },
    search(terms, done) {
      this.loading = true
      search(terms)
          .then(response => {
            this.loading = false
           let  extra = null
            if (terms) {
              extra = {
                label: `Adicionar "${terms}" como novo centro de custo`,
                value: terms,
                isNew: true
              }
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.nome,
                value: p.nome,
                active: null
              }
            })
            extra && result.push(extra)
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
  }
}
</script>
